import { AppBar, Toolbar, Typography, Box, Link } from '@mui/material';
import logo from './favicon-32x32.png';

export const Header = () => {
  const base = process.env.PUBLIC_URL || '';
  const home = base + '/#/';
  return (
    <AppBar
      position="static"
      style={{ background: '#0277bd' }}
      sx={{ marginBottom: 4 }}
    >
      <Toolbar>
        <Link href={home} color="inherit" underline="none">
          <Typography variant="h6" component="div">
            StenMe
            <Box
              sx={{
                verticalAlign: 'middle',
                paddingLeft: '5px',
                width: '32px',
                height: '32px',
              }}
              component="img"
              height="32"
              alt=""
              src={logo}
            />
          </Typography>
        </Link>
        <Box
          sx={{
            marginLeft: 'auto',
          }}
        ></Box>
      </Toolbar>
    </AppBar>
  );
};
